import { useState } from "react";
import { Box, Button, InputLabel, MenuItem, Modal, Select,  FormControl, Grid, TextField, Typography } from "@mui/material";
import { createTemplete, pushTestTemplete } from "services/nodejs.service";

interface LanguageData {
    on: boolean;
    id?: number;
    media: 'Activity' | 'Article' | 'Session' | 'Milestones'| '';
    templete?:string
    format?: 'IMAGE' | 'TEXT' | 'VIDEO' | '';
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}
  
export interface LanguageMap {
    spanish: LanguageData;
    english: LanguageData;
    portuges: LanguageData;
}

type Props = {
    close: ()=> void;
    visible: boolean;
    refresh: ()=>void;
}



function TempleteModal({close, visible, refresh}:Props) {
    const [languageId, setLanguageId] = useState("1")
    const [sendingPhone, setSendingPhone] = useState("")
    const [recievingPhone, setRecievingPhone] = useState("")
    const [templeteName, setTempleteName] = useState("")
    const [media, setMedia] = useState("0")
    const [templeteError, setTempleteError] = useState("")
    const [minorModal, setMinorModal] = useState(false)
    async function testTemplete() {
        setMinorModal(true)
        setTempleteError('')
        let templeteSend = await pushTestTemplete({
            languageId:+languageId,
            sendingPhone:+sendingPhone,
            recievingPhone:+recievingPhone,
            name:templeteName,
            type:+media
        })
        if(templeteSend.status==206) setTempleteError(templeteSend.data.err)
    }

    async function registerTemplete() {
        setMinorModal(true)
        setTempleteError("Templete creado correctamente")
        let createdTemplete = await createTemplete({
            language_id:+languageId,
            name:templeteName,
            type:+media
        })
        if(createdTemplete.status==208) {
            setTempleteError("Templete con esa combinacion de nombre y lenguaje ya existe")
        }
    }

    return (
        <Modal open={visible} >
        <form>
            <Box sx={modalStyle}>
                <Grid container>
                    <Grid container spacing={1/2} display={'flex'} flexDirection={'row'} p={1}>
                        <Grid item xs={4}>
                            <TextField label='Templete Name' onChange={(v)=>setTempleteName(v.target.value)} fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="language" >Lenguaje</InputLabel>
                                <Select labelId="language" id="language" label="Lenguaje" value={languageId} onChange={(v) => setLanguageId(v.target.value)}>
                                    <MenuItem key={1} value={1}>Español</MenuItem>
                                    <MenuItem key={2} value={2}>Ingles</MenuItem>
                                    <MenuItem key={3} value={4}>Portugués</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="media" >Media</InputLabel>
                                <Select labelId="media" id="mediaL" label="Media" value={media} onChange={(v) => setMedia(v.target.value)}>
                                    <MenuItem key={1} value={0}>N/A</MenuItem>
                                    <MenuItem key={2} value={1}>Imagen</MenuItem>
                                    <MenuItem key={3} value={2}>Video</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1/2}  style={{ display: 'flex', flexDirection: 'row' }} p={1}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="tqmt" >Telefono que mandara el templete</InputLabel>
                                <Select labelId="tqmtL" id="tqmt" label="Telefono que mandara el templete" value={sendingPhone} onChange={(v) => setSendingPhone(v.target.value)}>
                                    <MenuItem key={1} value={100499112882386}>(502) 476-09960</MenuItem>
                                    <MenuItem key={2} value={189898334205331}>(502) 476-26774</MenuItem>
                                    {/* <MenuItem key={3} value={256802684175549}>(502) 528-75196</MenuItem> */}
                                    <MenuItem key={4} value={155742407611819}>(502) 563-79681</MenuItem>
                                    {/* <MenuItem key={3} value={155742407611819}>50256379681</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField label='Telefono al cual mandarle el templete' onChange={(v)=>setRecievingPhone(v.target.value)} fullWidth/>
                        </Grid>
                        <Grid item xs={4} alignSelf={'center'}>
                            <Button fullWidth variant="contained" onClick={()=> testTemplete()}>Probar templete</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} p={1}>
                        <Button variant="contained" fullWidth onClick={registerTemplete}>Add</Button>
                    </Grid>
                    <Grid item xs={6} p={1}>
                        <Button variant="contained" fullWidth onClick={()=>{refresh(); close()}}>close</Button>
                    </Grid>
                
                </Grid>
        <Modal open={minorModal}>
            <Box sx={minorStyle}>
                <Grid container flexDirection={'row'}>
                    <Grid container p={1}>
                        <Grid item >
                            {!templeteError ? <Typography variant="h6" color={'black'}>Templete enviado correctamente</Typography> :<Typography variant="h6" color={'black'}>{templeteError}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={4}>
                            <Button variant="contained" onClick={()=>setMinorModal(false)}>Cerrar</Button>
                        </Grid> 

                    </Grid>

                </Grid>
            </Box>
        </Modal>
            </Box>
        </form>
        </Modal>

    )

}


const modalStyle = {
    position: 'absolute',
    top:'50%',
    left:'50%',
    bgcolor: 'white',
    p:4,
    display:'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    minWidth:'70%'
}

const minorStyle = {
    position: 'absolute',
    top:'50%',
    left:'50%',
    bgcolor: 'white',
    p:4,
    display:'flex',
    flexDirection: 'column',
    transform: 'translate(-50%, -50%)',
    // minWidth:'70%'

}

export default TempleteModal