import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MENU_ADMIN, MENU_PARTNERS } from '../../data/menu-data';

interface PropsI {
  isVisible: boolean
}

const Sidebar = ({isVisible}: PropsI) => {
  const [menu, setMenu] = useState<any>([]);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();


  useEffect(() => {
    if(user.isAdmin){
      setMenu(MENU_ADMIN);
    } else{
      setMenu(MENU_PARTNERS);
    }
  },[user])

  return (
    <>
      <ul
        className={`navbar-nav bg-gradient-primary ${isVisible? '':'d-none d-md-block'} sidebar sidebar-dark accordion`}
        id="accordionSidebar"
      >
        <Link
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="/admin/dashboard"
        >
          <div className="sidebar-brand-icon rotate-n-15">
            <i className="fas fa-laugh-wink"></i>
          </div>
          <div className="sidebar-brand-text mx-3">
            Afinidata <sup>&reg;</sup>
          </div>
        </Link>
        <hr className="sidebar-divider my-0" />

        <hr className="sidebar-divider" />
        {
          user.isAdmin &&
          (
            <>
            <div className="sidebar-heading">Administración</div>

              <li className="nav-item">
                <a
                  href="/#"
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <i className="fas fa-fw fa-user-circle"></i>
                  <span>Usuarios</span>
                </a>
                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">USUARIOS: </h6>
                    <Link className="collapse-item" to="/admin/users/">
                      Listado de usuarios
                    </Link>
                    <Link className="collapse-item" to="/admin/users/create">
                      Crear nuevo usuario
                    </Link>
                    <div style={{borderBottom: '1px solid #eee'}}></div>
                    <Link className="collapse-item" to="/admin/groups/">
                      Grupos
                    </Link>
                  </div>
                </div>
                <a
                  href="/#"
                  className="nav-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseMedia"
                  aria-expanded="true"
                  aria-controls="collapseMedia"
                >
                  <i className="fas fa-fw fa-user-circle"></i>
                  <span>Media</span>
                </a>
                <div
                  id="collapseMedia"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Media: </h6>
                    <Link className="collapse-item" to="/admin/media/images">
                      Listado de Imagenes
                    </Link>
                    <Link className="collapse-item" to="/admin/media/videos">
                      Listado de Videos
                    </Link>
                  </div>
                </div>
              </li>
              <hr className="sidebar-divider" />
            </>
           )
        }

        <div className="sidebar-heading">Plataforma</div>
        {menu.map(( item:any, index:number) => (
            <li className="nav-item" key={`sideoption_${item.id}`}>
              <a
                href="/#"
                className="nav-link collapsed"
                data-toggle="collapse"
                data-target={`#collapse${index}`}
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className={item.icon}></i>
                <span>{t(item.name)}</span>
              </a>
              <div
                id={`collapse${index}`}
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">{item.subtitle} </h6>
                  {item.items.map( (link: any, i: number) => (
                    <Link className="collapse-item" to={link.path}
                    key={link.id}>
                      {t(link.name)}
                    </Link>
                  ))}
                </div>
              </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Sidebar;
