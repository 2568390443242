import { NodejsServiceRequest } from "api/NodejsRequest";

export const pushTestTemplete = (data:any) => {
    return NodejsServiceRequest.post(`/push/templetes/test`, data);
}

export const createTemplete = (data:any) => {
    return NodejsServiceRequest.post(`/push/templetes/`, data);
}

export const getAllTempletes = () => {
    return NodejsServiceRequest.get(`/push/templetes/`);
}

export const deleteATemplete = (data:any) => {
    return NodejsServiceRequest.delete(`/push/templetes/?id=${data.id}`);
}

export const getLanguageTemples = () => {
    return NodejsServiceRequest.get(`/push/templetes/`);
}

export const createPushService = (data:any) => {
    return NodejsServiceRequest.post(`/push/push/`, data);
}

export const updatePushService = (data:any) => {
    return NodejsServiceRequest.put(`/push/push/`, data);
}

export const checkName = (data:any) => {
    return NodejsServiceRequest.get(`/push/objects/checkName?name=${data}`);
}

export const getS3Link = (name:string,type:string) => {
    return NodejsServiceRequest.get(`/push/objects/link?name=${name}&type=${type}`);
}

export const createObjInDB = (data:any) => {
    return NodejsServiceRequest.post(`/push/objects/`, data);
}

export const getAllObjects = () => {
    return NodejsServiceRequest.get(`/push/objects/`);
}

export const deleteObjectService = (data:any) => {
    return NodejsServiceRequest.delete(`/push/objects/?id=${data.id}&name=${data.name}&type=${data.type}`);
}

export const getAllPush = () => {
    return NodejsServiceRequest.get(`/push/push/dashboard`);
}

export const deleteAPush = (data:any) => {
    return NodejsServiceRequest.delete(`/push/push/?id=${data.id}`);
}

export const updatePostwVideo = (data:any) => {
    return NodejsServiceRequest.post(`/push/objects/post`, data);
}