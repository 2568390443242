import * as Yup from "yup";
import swal from "sweetalert";
import { useFormik } from "formik";
import { login, setToken, getToken, decodeToken } from '../../data/users';
import { useHistory, Redirect, Link } from 'react-router-dom';
import { useContext, useState } from "react";
import { UserContext } from "context/UserContext";
import { useTranslation } from "react-i18next";
import i18n from "i18nextConf";
import Loading from "components/Loading";
import bgLogin from '../../assets/login-bg.png'

const validationSchema = Yup.object().shape({
  username: Yup.string().required(i18n.t('user_required')),
  password: Yup.string().required(i18n.t('password_required')),
});

const Login = () => {

  const {t, ready, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);

  const {setUserInfo} = useContext(UserContext)

  const history = useHistory();

  const handleSubmit = (payload: any): void => {
    setLoading(true);

    login(payload)
    .then( (resp:any) => {

      const { data } = resp;
      const token = resp.data.access;
      setToken(data);

      swal('Afinidata',t("user_valid"),'success')
      .then(() => {
        setLoading(false);
        const decode = decodeToken();
        setUserInfo({
          token,
          isAdmin: decode.superuser
        });
        history.push('/admin/dashboard');

      })
    })
    .catch( err => {
      setLoading(false);
      swal("Afinidata",t('user_invalid'),"error")
    });
  };

  const initialValues = {
    username: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  if( getToken() ){
    return <Redirect to="/admin/dashboard" />
  }

  if(!ready){
    return <Loading />
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block"
                  style={{
                    backgroundImage: `url(${bgLogin})`,
                    backgroundSize: "cover",
                    backgroundPosition: 'center center',
                    backgroundAttachment: 'scroll',
                    backgroundRepeat: 'no-repeat'
                  }}></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <img
                          src="/img/logo.png"
                          alt=""
                          style={{
                            width: "200px",
                            height: "auto",
                            display: "block",
                            margin: "12px auto",
                          }}
                        />
                      </div>
                      <form className="user" onSubmit={formik.handleSubmit}>
                        <div className="form-group">
                          <input
                            type="username"
                            placeholder={t('username')}
                            name="username"
                            className={`form-control form-control-user ${
                              formik.errors.username && formik.touched.username
                                ? "is-invalid"
                                : ""
                            }`}
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                            {formik.errors.username && formik.touched.username ? (
                                <div className="invalid-feedback">{t(formik.errors.username)}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            className={`form-control form-control-user ${
                                formik.errors.password && formik.touched.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              value={formik.values.password}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder={t('password')}
                          />
                            {formik.errors.password && formik.touched.password ? (
                                <div className="invalid-feedback">{t(formik.errors.password)}</div>
                            ) : null}
                        </div>
                        <button className="btn btn-primary btn-user btn-block"
                        type="submit"
                        disabled={loading}>
                          {t("ingresar")}
                        </button>
                      </form>
                      <hr />
                      <div className="row">
                        <div className="col text-center">
                          <p>{t('seleccionar_idioma')}:</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                            <button onClick={() => handleLanguageChange("es")}
                            className="btn btn-primary btn-sm">
                              {t('spanish')}
                            </button>
                        </div>
                        <div className="col text-center">
                            <button onClick={() => handleLanguageChange("pr")}
                            className="btn btn-primary btn-sm">
                              {t('portugues')}
                            </button>
                        </div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <Link to="/password-forget" className="small">
                          {t('olvido_clave')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
