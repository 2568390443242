import { Box, Button, Modal, Grid, Typography } from "@mui/material";
import { deleteAPush } from "services/nodejs.service";

function formatDate(date: Date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const channel_map: any = {
    0: "APP POR PROGRAMA",
    1: "APP BRAZIL",
    2: "APP BOTNAR",
    50: "AFINIAPP",
    100: "WHATSAPP PROGRAMA",
    101: "WHATSAPP BRAZIL",
    102: "WHATSAPP BOTNAR",
  };
  const output_map = ["Activity", "Article", "Session", "Milestones"];

function PushDeleteModal({ pushToDelete, close, visible, refresh }: any) {
  const deleteTemplete = async (id: any) => {
    await deleteAPush({ id: id });
    await refresh();
    close();
  };
  console.log("delete", pushToDelete);
  return (
    <Modal open={visible}>
      <Box sx={minorStyle}>
        <Grid container flexDirection={"row"}>
          <Grid container p={1}>
            <Grid item>
              <Typography variant="h6" color={"black"}>
                Seguro que quieres borrar la push:
              </Typography>
              <Typography variant="h6" color={"black"}>
                Fecha: {formatDate(pushToDelete.date_to_send)}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Canal: {channel_map[pushToDelete.channel]}
              </Typography>
              <Typography variant="h6" color={"black"}>
                Output: {output_map[pushToDelete.output]}
              </Typography>

            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => deleteTemplete(pushToDelete.id)}
              >
                Si
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={()=>close()}>
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export { PushDeleteModal };
